import React, { memo } from 'react';

import HomeSnippetWrapper from 'components/Home/HomeSnippetWrapper';
import HomeHeader from 'components/Home/HomeHeader';
import { Event, Timeline } from 'components/common/Timeline';

import styles from './styles.module.scss';

const tech = {
  react: 'React.js',
  redux: 'Redux',
  ts: 'TypeScript',
  i18n: 'i18n',
  next: 'Next.js',
  nest: 'NestJS',
  sc: 'Styled Components',
  sb: 'Storybook',
  scss: 'scss',
  docz: 'Docz / Internal Design System',
  vc: 'Victory Charts',
  semantic: 'Semantic Release',
  circle: 'CircleCI',
  travis: 'TravisCI',
  relay1: 'Relay 1.x',
  apollo: 'Apollo Client',
  mongodb: 'MongoDB',
  chakraui: 'Chakra UI',
  mui: 'Material UI',
  contentful: 'Contentful'
};

const data = {
  companies: {
    kw: 'Keller Williams Realty Inc.',
    netguru: 'Netguru',
    ccg: 'Cambridge Cancer Genomics',
    ctv: 'Canvs.tv',
    vrsg: 'VR Smart Guide',
    jetbridge: 'JetBridge',
    nvta: 'Invitae',
  },
  jobTitles: {
    jrfe: 'Junior Frontend Engineer',
    fe: 'Frontend Engineer',
    srfe: 'Senior Frontend Engineer',
    srfetl: 'Senior Frontend Engineer & Team Lead',
  },
};

const data2 = {
  companies: [
    {
      company: data.companies.nvta,
      subCompany: data.companies.jetbridge,
      jobTitle: data.jobTitles.srfe,
      interval: 'Dec 2020 - Feb 2024 (3 years 2 months)',
      responsibilities: [
        'Significantly reduced the setup time for Contentful, Launch Darkly, SEO, and other shared integrations across multiple projects. Reduced dedicated sprint setup tasks to just 2-3 minutes for developers, eliminating the need for in-depth, low-level familiarity.',
        'Developed multiple Contentful blocks and a flexible content migration script.',
        'Enhanced code quality across dozens of frontend repositories.',
        'Improved observability through the implementation of New Relic.',
        'Established guidelines for Frontend Architecture, Accessibility, Cypress E2E, and Code Quality, benefiting approximately 100 Frontend Engineers.',
        'Developed a common Cypress E2E package, supporting all environments for 8 teams.',
        'Elevated Google’s Lighthouse accessibility scores from an average of ~50 to over 95 across multiple projects.',
        'Ensured full website accessibility on all major devices and screen readers, including NVDA, JAWS, and VoiceOver.',
        'Introduced video.js support for enhanced video capabilities, transitioning from the Wistia provider.',
        'Revamped and optimized SVG Decorative Shapes within content sections.'
      ],
      techStack: [tech.react, tech.next, tech.sb, tech.contentful],
    },
    {
      company: data.companies.jetbridge,
      jobTitle: data.jobTitles.srfetl,
      interval: 'Dec 2020 - present',
      responsibilities: [
        'Leading a team of 9 Engineers.',
        'Revamped and improved the internal Developers\' feedback process, enhancing team communication and efficiency.'
      ],
      techStack: [tech.react, tech.next],
    },
    {
      company: data.companies.vrsg,
      subCompany: data.companies.netguru,
      jobTitle: data.jobTitles.fe,
      interval: 'May 2020 - Nov 2020 (7 months)',
      responsibilities: [
        'Developed a new Contracts module, enhancing the application’s functionality and user experience.',
        'Implemented a comprehensive PDF handling feature, including file upload, preview (with single and batch download options in both preview and fullscreen modes).',
        'Transitioned legacy project code to TypeScript, improving code reliability and maintainability.',
        'Resolved issues in the registration flow, optimizing the user onboarding process.'
      ],
      techStack: [tech.react, tech.redux, tech.ts, tech.i18n, tech.sb, tech.sc],
    },
    {
      company: data.companies.kw,
      subCompany: data.companies.netguru,
      jobTitle: data.jobTitles.fe,
      interval: 'November 2018 - April 2020 (1.5 years)',
      header: 'FrontDoor Platform',
      responsibilities: [
        'Developed a Dashboard module for the FrontDoor platform using Next.js, React.js, Redux, and SCSS, serving approximately 180,000 daily users.',
        'Successfully delivered the Referrals module under a tight deadline for an event showcase to ~15,000 users, one of three key modules promised.',
        'Led a team of 4 frontend engineers, guiding project development and team dynamics.',
        'Optimized the FrontDoor platform\'s release process, reducing it by 1 day.',
        'Co-created an internal translations package and applied it to 7 FrontDoor modules, enhancing multi-language support.',
        'Mentored new developers joining the FrontDoor project, facilitating their integration and skill development.',
        'Developed and maintained components in Keller William’s design system, ensuring consistency and quality.',
        "Forked and customized an unmaintained open-source package to meet specific business requirements.",
        'Implemented 6 data visualization components using Victory Charts, a D3 wrapper for React.js, to enhance data representation.',
        'Created a common package for FrontDoor modules, consolidating commonly used utilities and components.',
        'Integrated Netguru code standards into the existing FrontDoor codebase, improving code quality and consistency.',
        'Estimated and implemented translation solutions for 2 Keller Williams modules, expanding their accessibility.',
        'Maintained and updated various existing FrontDoor modules, ensuring operational excellence.'
      ],
      techStack: [
        tech.react,
        tech.redux,
        tech.next,
        tech.scss,
        tech.docz,
        tech.i18n,
        tech.vc,
        tech.semantic,
        tech.circle,
        tech.travis,
      ],
    },
    {
      company: data.companies.ctv,
      subCompany: data.companies.netguru,
      jobTitle: data.jobTitles.jrfe,
      interval: 'October 2018 - October 2018 (1 month)',
      header: 'Target Audience',
      responsibilities: [
        'Initiated and successfully implemented Apollo Client as a new framework in the Target Audience module, optimizing data management and API interactions.',
        'Resolved issues and enhanced functionality in other existing modules, contributing to overall project stability and performance.'
      ],
      techStack: [
        tech.react,
        tech.relay1,
        tech.apollo,
        tech.node,
        tech.mongodb,
        tech.scss,
      ],
    },
    {
      company: data.companies.ccg,
      subCompany: data.companies.netguru,
      jobTitle: data.jobTitles.jrfe,
      interval: 'July 2018 - September 2018 (3 months)',
      responsibilities: [
        'Conducted a comprehensive overhaul and optimization of existing views in the application, significantly enhancing user interface performance and user experience.'
      ],
      techStack: [tech.react, tech.sb, tech.sc],
    },
    {
      company: data.companies.netguru,
      interval: 'July 2018 - Nov 2020',
      jobTitle: data.jobTitles.fe,
      responsibilities: [
        'Co-created and contributed to an internal React project template, establishing a standardized and efficient development process.',
        'Authored and co-developed the new React project creation process, detailed in an internal blog post, setting guidelines for future projects.',
        'Played a key role in estimating upcoming projects, ensuring accurate planning and resource allocation.',
        'Served as an internal reviewer for frontend-related blog posts, maintaining content quality and relevance.',
        'Regularly contributed to the internal newsletter with a curated selection of the latest articles on frontend, GraphQL, and Node.js.',
        'Led internal Frontend meetings, facilitating knowledge sharing and team collaboration.',
        'Mentored several internal and external team members across different projects, enhancing team skills and project outcomes.'
      ],
    }
  ],
};

const TimelineSnippet = () => (
  <HomeSnippetWrapper>
    <HomeHeader>Timeline</HomeHeader>
    <div className={styles.timelineWrapper}>
      <Timeline>
        {data2.companies.map(
          (
            {
              interval,
              company,
              subCompany,
              jobTitle,
              header,
              responsibilities,
              techStack,
            },
            index
          ) => (
            <Event
              company={`${company}_${index}`}
              interval={interval}
              title={company}
              subtitle={subCompany}
              jobTitle={jobTitle}
              key={`${company}_${index}`}
            >
              <Event.Subtitle>{header}</Event.Subtitle>
              <Event.Paragraph header="Responsibilities">
                {responsibilities.map((responsibility) => (
                  <div key={responsibility}>
                    - {responsibility}
                    <br />
                  </div>
                ))}
              </Event.Paragraph>
              {techStack && (
                <Event.Paragraph header="Tech Stack">
                  {techStack.join(', ')}
                </Event.Paragraph>
              )}
            </Event>
          )
        )}
      </Timeline>
    </div>
  </HomeSnippetWrapper>
);

export default memo(TimelineSnippet);
