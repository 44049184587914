import React from 'react';
import { string } from 'prop-types';

import Header from 'components/common/Header';

import styles from './styles.module.scss';

const TestimonialSlide = ({
  company,
  companyUrl,
  person,
  role,
  testimonial,
  testimonialDate,
}) => (
  <>
    <div className={styles.testimonialWrapper}>
      <p
        className={styles.testimonial}
        tabIndex={0}
        dangerouslySetInnerHTML={{ __html: testimonial }}
      />
    </div>
    <div className={styles.person}>
      <p>{testimonialDate}</p>
      <Header as="h4" textShadow>
        {person}
      </Header>
      <div className={styles.position}>
        {role} at{' '}
        <a href={companyUrl} rel="noopener noreferrer" target="_blank">
          {company}
        </a>
      </div>
    </div>
  </>
);

TestimonialSlide.propTypes = {
  company: string.isRequired,
  companyUrl: string.isRequired,
  person: string.isRequired,
  role: string.isRequired,
  testimonial: string.isRequired,
  testimonialDate: string.isRequired,
};

export default TestimonialSlide;
