import React from 'react';
import { oneOf, bool, string } from 'prop-types';

const Header = ({ as: T, className, textShadow, ...props }) => (
  <T
    className={className}
    style={{ textShadow: textShadow ? '1px 1px black' : undefined }}
    {...props}
  />
);

Header.propTypes = {
  as: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  className: string,
  textShadow: bool,
};

Header.defaultProps = {
  as: 'h3',
  className: '',
  textShadow: null,
};

export default Header;
