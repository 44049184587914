import React, { memo } from 'react';
import { node } from 'prop-types';
import Header from 'components/common/Header';

import styles from '../styles.module.scss';

const HomeHeader = ({ children }) => (
  <Header as="h2" className={styles.header}>
    {children}
  </Header>
);

HomeHeader.propTypes = {
  children: node.isRequired,
};

export default memo(HomeHeader);
