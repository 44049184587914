import React, { memo } from 'react';
import cx from 'classnames';
import { node, string } from 'prop-types';

import styles from './styles.module.scss';

const BodyText = ({ className, children }) => (
  <p className={cx(styles.bodyText, className)}>{children}</p>
);

BodyText.propTypes = {
  children: node,
  className: string,
};
BodyText.defaultProps = {
  children: null,
  className: '',
};

export default memo(BodyText);
