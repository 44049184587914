import React, { memo } from 'react';
import cx from 'classnames';
import { node, string } from 'prop-types';

import styles from './styles.module.scss';

const HomeSnippetWrapper = ({ children, className }) => (
  <div className={cx(styles.homeSnippetWrapper, className)}>{children}</div>
);

HomeSnippetWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};

HomeSnippetWrapper.defaultProps = {
  className: null,
};

export default memo(HomeSnippetWrapper);
