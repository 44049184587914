import React from 'react';
import { node } from 'prop-types';

import styles from './styles.module.scss';

const Timeline = ({ children }) => (
  <ul className={styles.timeline}>{children}</ul>
);

export default Timeline;

Timeline.propTypes = {
  children: node.isRequired,
};
