import React from 'react';

import HomeSnippetWrapper from 'components/Home/HomeSnippetWrapper';
import HomeHeader from 'components/Home/HomeHeader';
import Carousel from 'components/common/Carousel';
import TestimonialSlide from 'components/Home/TestimonialsSnippet/Slide';

const companies = {
  kw: {
    name: 'Keller Williams Realty Inc.',
    url: 'https://technology.kw.com/',
  },
  sp: {
    name: 'SoftPyramid',
    url: 'https://softpyramid.com/',
  },
  jb: {
    name: 'JetBridge',
    url: 'https://jetbridge.com/',
  },
};

const TestimonialsSnippet = () => {
  const data = [
    {
      testimonialDate: '10/2018',
      person: 'George Kontos',
      company: 'canvs.ai',
      companyUrl: 'https://www.canvs.ai/',
      role: 'CTO',
      testimonial:
        'Sebastian worked with us, for a bit over a month, on developing a very complicated feature like Target Audience. <br />His technical skills were certainly tested during his internship with Canvs. <br />His ability to get all assignments completed under stressful deadlines is a testament to his strengths in this field.',
    },
    {
      testimonialDate: '09/2019',
      person: 'Igor Widawski',
      company: 'Netguru',
      companyUrl: 'https://www.netguru.com/',
      role: 'Project Manager',
      testimonial:
        "I didn't have many occasions to work with Sebastian since we met during mentoring two external developers. Generally from Sebastian you can feel solid approach, he applies himself to what he does and in our case it was just a code review buddy. <br /><br />He is really engaged in his work, he thinks 'for someone' that is he tries to predict different outcomes of the possible scenarios to the problem(s). <br /><br />He is also proactive, very communicative and actively looks for solutions to maximize said solutions quality.",
    },
    {
      testimonialDate: '09/2019',
      person: 'Gio Bagtas',
      company: companies.kw.name,
      companyUrl: companies.kw.url,
      role: 'Engineering Manager',
      testimonial:
        "I do not work with him on the regular basis but he is simply awesome (and it's not only my feeling, but also PM from KW that was working with him for the long time). <br /><br />He is dealing with difficult project, difficult client, in difficult flow (that is still far away from Netguru standards). And he doesn't complain! He simply does his job the best he can and going one step ahead to move the work further. <br /><br />I know that I can rely on him if there will be any red light he will let me know. Even though he is working as a team extension he doesn't just do his tickets and that's it. <br /><br />Thanks to the catch up calls with him I see that he is committed to the project, wants to support it and shares his opinion and insight with the rest of the team.",
    },
    {
      testimonialDate: '09/2019',
      person: 'Ben Elvira',
      company: companies.kw.name,
      companyUrl: companies.kw.url,
      role: 'Product Manager',
      testimonial:
        'Sebastian communicates well with all members of the team in stand ups and is organized in the communication of items that he documents too. Sebastian is a valuable contributor and a good team player. Pleasure to have him in the FrontDoor team.',
    },
    {
      testimonialDate: '04/2020',
      person: 'Magdalena Dratwa',
      company: 'Netguru',
      companyUrl: 'https://www.netguru.com/',
      role: 'Project Manager',
      testimonial:
        'Before getting into details about our cooperation I want to mention that I would REALLY like to work with Sebastian in the next project, preferably not as a team extension. <br /><br />' +
        "Even though we didn't work together on a daily basis for the past 1.5 years I observed a lot during that time to come to this conclusion. Here are my key observations: <br /><br />" +
        '1. He has a huge ownership of the tasks that are given to him and in his case it is more than "just" delivering them from the start to finish. In the project Sebastian was in it was necessary to add one more step, that is, more than often starting initiative of adding missing elements which client didn\'t think of.<br /><br />' +
        "2. For the whole time I didn't hear any negative feedback from client perspective about Sebastian. On the contrary all of his Product Owners were delighted to work with him.<br /><br />" +
        '3. For almost a year he was working alone from Netguru side. In Keller Williams we had a lot of teams as an extension and majority of people were working on mixed configurations when it comes to team members. Realistically only few could work for a longer period of time with a team from client side and be happy about the cooperation. From my perspective it requires flexibility and skill to build relations with other team members and Sebastian undoubtedly has shown that.<br /><br />' +
        '4. For me Sebastian is a person that I just know I can trust and rely on. He is proactive, doesn\'t wait until someone solves his problem. <br /><br />He has that outstanding "project maturity" that for me already leveled up to a senior level which often is harder to achieve than hard skills related to programming.',
    },
    {
      testimonialDate: '09/2019',
      person: 'Farooq Khalid',
      company: companies.sp.name,
      companyUrl: companies.sp.url,
      role: 'CTO',
      testimonial:
        'Sebastian has always shown a high level of performance, I can not point out one area that he needs to start doing as he is currently doing a good job at all levels.',
    },
    {
      testimonialDate: '03/2019',
      person: 'Kharla Barrios',
      company: companies.kw.name,
      companyUrl: companies.kw.url,
      role: 'Product Manager',
      testimonial:
        'Working with Sebastian has been awesome, he is one of the most well-rounded devs I have worked with. <br /><br />Beside his technical expertise, he has a great and positive can-do attitude, cool personality and charisma. Sebastian is always looking forward findings ways to define/implement better solutions to tackle the challenges. He is a great team player with the ability to take leading roles to define technical solutions. <br /><br />I have had Sebastian working in different challenges, 1) internationalization of some Console components, 2) Additional support for Referrals team to be ready for family Reunion and 3) FrontDoor. <br /><br />' +
        'When working on Referrals Sebastian put 200% of his time and effort, even working over a weekend showing more commitment to get the App ready for the event (which we managed to do) than the Referrals team members and completed an assessment on what the team had implemented recommending how to stabilize and improve Referrals implementation. <br /><br />' +
        'In FrontDoor he has created new components related to data visualization that have become part of the UI Kit and is doing an awesome work implementing the Dashboards. <br /><br />' +
        'I feel grateful of having Sebastian on my team and confident that any task on his hands will get executed with excellence.<br />' +
        "Sebastian has made numerous contributions to the Real UI kit as well as the frontdoor applications. He's been great to work with. <br />",
    },
    {
      testimonialDate: '04/2020',
      person: 'Kenneth Ubaldo Luján Rosas',
      company: companies.kw.name,
      companyUrl: companies.kw.url,
      role: 'Engineering Lead',
      testimonial:
        "Sebastian is being proactive. I think it's one of the qualities that has led Sebastian to make several successful contributions to our internal apps and tooling projects.",
    },
    {
      testimonialDate: '04/2020',
      person: 'Michael McGrail',
      company: companies.kw.name,
      companyUrl: companies.kw.url,
      role: 'Product Manager',
      testimonial:
        'What Sebastian does so well in his success as a stakeholder on the team, is his involvement in understanding the business cases and structure to provide a well round contribution to his work and the work of others in the project.',
    },
    {
      testimonialDate: '10/2021',
      person: 'John Sung Kim',
      company: companies.jb.name,
      companyUrl: companies.jb.url,
      role: 'CEO',
      testimonial:
        'Sebastian is a rare animal who is both a wizard technically but an incredible communicator. As such, he has a "multiplier effect" on the people he works with and has a knack for making his team members better. Every engineering team needs a Sebastian. Sadly, there\'s not enough.'
    },
  ];
  return (
    <HomeSnippetWrapper>
      <HomeHeader>Testimonials</HomeHeader>
      <Carousel data={data.reverse()} SlideComponent={TestimonialSlide} />
    </HomeSnippetWrapper>
  );
};

export default TestimonialsSnippet;
