import React, { memo, useState } from 'react';
import { arrayOf, shape, func } from 'prop-types';
import cx from 'classnames';
import Pagination from 'components/common/Carousel/Pagination';
import Slide from 'components/common/Carousel/Slide/index';

import styles from './styles.module.scss';

const Carousel = ({ data, SlideComponent }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const dataLength = data.length;

  const prevSlide = () => {
    const prevSlideIndex = activeSlide - 1;

    if (prevSlideIndex < 0) {
      return setActiveSlide(dataLength - 1);
    }

    return setActiveSlide(prevSlideIndex);
  };

  const nextSlide = () => {
    const nextSlideIndex = activeSlide + 1;

    if (nextSlideIndex === dataLength) {
      return setActiveSlide(0);
    }

    return setActiveSlide(nextSlideIndex);
  };

  const handleKeydown = e => {
    if (e.keyCode === 37) {
      return prevSlide();
    }

    if (e.keyCode === 39) {
      return nextSlide();
    }
  };

  const handlePaginationClick = slideIndex => setActiveSlide(slideIndex);

  return (
    <div className={styles.carousel} tabIndex={0} onKeyDown={handleKeydown}>
      <div className={cx(styles.arrow, styles.arrow__prev)} onClick={prevSlide}>
        ❮
      </div>
      <div className={styles.slides}>
        {data.map((d, i) => (
          <Slide key={`slide_${i}`} isActive={activeSlide === i}>
            <SlideComponent {...d} />
          </Slide>
        ))}
      </div>
      <div className={cx(styles.arrow, styles.arrow__next)} onClick={nextSlide}>
        ❯
      </div>
      <Pagination
        activeSlide={activeSlide}
        handlePaginationClick={handlePaginationClick}
        data={data}
      />
    </div>
  );
};

Carousel.propTypes = {
  data: arrayOf(shape({})).isRequired,
  SlideComponent: func.isRequired,
};

export default memo(Carousel);
