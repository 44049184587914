import React, { memo } from 'react';
import HomeSnippetWrapper from 'components/Home/HomeSnippetWrapper';
import HomeHeader from 'components/Home/HomeHeader';
import SocialMedia from 'components/Home/ContactSnippet/SocialMedia';
import cx from 'classnames';

import styles from './styles.module.scss';

const ContactSnippet = () => (
  <HomeSnippetWrapper>
    <HomeHeader>Contact</HomeHeader>
    <div
      className={cx(styles.contact, 'd-flex flex-column align-items-center')}
    >
      <SocialMedia />
    </div>
  </HomeSnippetWrapper>
);

export default memo(ContactSnippet);
