import React, { memo } from 'react';
import { bool, element } from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

const Slide = ({ isActive, children }) => {
  const slideClasses = {
    [styles.slide]: true,
    [styles.slide__active]: isActive,
  };

  return <div className={cx(slideClasses)}>{children}</div>;
};

Slide.propTypes = {
  children: element.isRequired,
  isActive: bool,
};

Slide.defaultProps = {
  isActive: false,
};

export default memo(Slide);
