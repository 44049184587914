import React from 'react';
import { arrayOf, string } from 'prop-types';

import HomeSnippetWrapper from 'components/Home/HomeSnippetWrapper';
import HomeHeader from 'components/Home/HomeHeader';
import Header from 'components/common/Header';
import Carousel from 'components/common/Carousel';

import hdlsImage from 'images/hlds.jpg';
import hlImage from 'images/hl.jpg';
import pcImage from 'images/pc.jpg';
import exilenceImage from 'images/exilence.jpg';

import styles from './styles.module.scss';

const projects = [
  {
    name: 'Physics Calc',
    url: 'https://physics-calc.netlify.com/',
    source: 'https://github.com/sbsrnt/physics-calc-v2',
    image: pcImage,
    techStack: ['React.js', 'TypeScript', 'scss'],
    desc: 'Advanced Physics Calculator.',
  },
  {
    name: 'Exilence Next (Open Source) | Maintainer',
    url: 'https://github.com/viktorgullmark/exilence-next',
    source: 'https://github.com/viktorgullmark/exilence-next',
    image: exilenceImage,
    techStack: ['React.js', 'TypeScript', 'Electron.js'],
    desc:
      "Exilence Next is a desktop application that helps you calculate how valuable your Path of Exile's character, inventory and stash tabs are.",
  },
  {
    name: 'Sphene (API)',
    url: 'https://github.com/sbsrnt/api-sphene',
    source: 'https://github.com/sbsrnt/api-sphene',
    techStack: ['NestJS', 'TypeScript', 'MongoDB'],
    desc:
      'API for Sphene. Sphene is a desktop application focused on system notifications storing and reminding about your upcoming payments.',
  },
  {
    name: 'Hearthlounge Design System',
    url: 'https://hearthlounge-design-system.netlify.app/',
    source: 'https://github.com/sbsrnt/hearthlounge-design-system',
    npm: 'https://www.npmjs.com/package/hearthlounge-design-system',
    image: hdlsImage,
    techStack: ['React.js', 'Storybook', 'scss'],
    desc: 'Style guide for building and improving HearthLounge',
  },
  {
    name: 'Hearthlounge',
    url: 'https://sbsrnt.github.io/HearthLounge/#/welcome',
    source: 'https://github.com/sbsrnt/HearthLounge',
    image: hlImage,
    techStack: ['Firebase', 'React.js', 'Redux', 'scss'],
    desc: 'Web application based on Hearthstone game.',
    note:
      'Project is using a lot of 3rd party APIs. As the time passed by (developed in 2017) some of them got outdated thus negatively affecting the project significantly.',
  },
];

const ProjectsSlide = ({
  name,
  url,
  source,
  npm,
  image,
  techStack,
  desc,
  note,
}) => (
  <div className={styles.wrapper}>
    {image && (
      <a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        className={styles.imgWrapper}
      >
        <img src={image} alt={`${name} preview`} className={styles.img} />
      </a>
    )}
    <div className={styles.techStack}>
      <div className={styles.links}>
        <a href={url} rel="noopener noreferrer" target="_blank">
          DEMO
        </a>{' '}
        ·{' '}
        <a href={source} rel="noopener noreferrer" target="_blank">
          GITHUB
        </a>
        {npm && (
          <>
            {' '}
            ·{' '}
            <a href={npm} rel="noopener noreferrer" target="_blank">
              NPM
            </a>
          </>
        )}
      </div>
      {techStack.join('  ·  ')}
    </div>
    <Header as="h3" textShadow>
      <a href={url} rel="noopener noreferrer" target="_blank">
        {name}
      </a>
    </Header>
    <p className={styles.desc}>{desc}</p>
    {note && (
      <i className={styles.note}>
        <span>NOTE:</span>
        {note}
      </i>
    )}
  </div>
);

ProjectsSlide.propTypes = {
  name: string.isRequired,
  url: string.isRequired,
  source: string.isRequired,
  npm: string,
  image: string,
  techStack: arrayOf(string).isRequired,
  desc: string.isRequired,
  note: string,
};

ProjectsSlide.defaultProps = {
  note: null,
  npm: null,
  image: null,
};

const ProjectsSnippet = () => (
  <HomeSnippetWrapper>
    <HomeHeader>Personal Projects</HomeHeader>
    <Carousel data={projects} SlideComponent={ProjectsSlide} />
  </HomeSnippetWrapper>
);
export default ProjectsSnippet;
