import React from 'react';
import cx from 'classnames';

import SvgLinkedin from 'components/common/Icons/Linkedin';
import SvgMail from 'components/common/Icons/Mail';
import SvgGithub from 'components/common/Icons/Github';

import styles from './styles.module.scss';

const SocialMedia = () => (
  <div className={cx(styles.socialMedia, 'd-flex')}>
    <a
      href="https://www.linkedin.com/in/sbsrnt/"
      rel="noopener noreferrer"
      target="_blank"
      title="Linkedin profile"
    >
      <SvgLinkedin />
      sbsrnt
    </a>
    <a
      href="mailto:eskrzy@gmail.com"
      rel="noopener noreferrer"
      target="_blank"
      title="e-mail"
    >
      <SvgMail />
      eskrzy@gmail.com
    </a>
    <a
      href="https://github.com/sbsrnt"
      rel="noopener noreferrer"
      target="_blank"
      title="github profile"
    >
      <SvgGithub />
      sbsrnt
    </a>
  </div>
);

export default SocialMedia;
