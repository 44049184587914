import React from 'react';
import { arrayOf, func, number, shape } from 'prop-types';

import styles from './styles.module.scss';

const Pagination = ({ data, activeSlide, handlePaginationClick }) => (
  <ul className={styles.pagination}>
    {data.map((_, i) => (
      <li
        key={`pagination_${i}`}
        className={activeSlide === i ? styles.pagination__active : ''}
        onClick={() => handlePaginationClick(i)}
      />
    ))}
  </ul>
);

Pagination.propTypes = {
  activeSlide: number.isRequired,
  data: arrayOf(shape({})).isRequired,
  handlePaginationClick: func.isRequired,
};

export default Pagination;
