import React, { memo } from 'react';
import { node } from 'prop-types';

import Header from 'components/common/Header';

const EventSubtitle = ({ children }) => (
  <Header as="h4" textShadow>
    {children}
  </Header>
);

EventSubtitle.propTypes = {
  children: node,
};

EventSubtitle.defaultProps = {
  children: null,
};

export default memo(EventSubtitle);
