import React from 'react';
import BodyText from 'components/common/BodyText';

import styles from '../styles.module.scss';

const AboutMeText = () => (
  <div className={styles.text}>
    <BodyText>
      I am a <span className={styles.highlight}>Sr. Frontend Engineer & Team Lead</span>{' '}
      based in Gdańsk, Poland, holding dual citizenship in the U.S. and Poland (EU
      member).
    </BodyText>
    <BodyText>
      <span className={styles.highlight}>I have a passion for</span>
      {' '} cats
      <span className={styles.emoji} role="img" aria-label="cats">
         😸
      </span>
      , PC gaming{' '}
      <span className={styles.emoji} role="img" aria-label="gaming">
        🎮
      </span>, tea{' '}
      <span className={styles.emoji} role="img" aria-label="tea">
        🍵
      </span>
      , Korean food{' '}
      <span className={styles.emoji} role="img" aria-label="korean">
         🇰🇷
      </span>{', '}
      and <span className={styles.highlight}>problem-solving.</span>
    </BodyText>
    <BodyText>
      Occasionally, I share my programming expertise on{' '}
      <a
        href="https://twitch.tv/sbsrnt"
        rel="noopener noreferrer"
        target="_blank"
      >
        Twitch.tv
      </a>{' '}
      by streaming personal projects and engaging in small chats with visitors to my channel.
    </BodyText>
    <BodyText>
      Previously, I was a maintainer of the desktop Open Source project {' '}
      <a
        href="https://github.com/viktorgullmark/exilence-next"
        rel="noopener noreferrer"
        target="_blank"
      >
        Exilence Next (2020-2023).
      </a>
    </BodyText>
  </div>
);

export default AboutMeText;
