import React, { memo } from 'react';
import cx from 'classnames';
import HomeSnippetWrapper from 'components/Home/HomeSnippetWrapper';
import HomeHeader from 'components/Home/HomeHeader';
import AboutMeText from 'components/Home/AboutMeSnippet/Text';
import profileImg from 'images/profile.jpg';
import styles from './styles.module.scss';

const AboutMeSnippet = () => (
  <HomeSnippetWrapper>
    <HomeHeader>About Me</HomeHeader>
    <div
      className={cx('d-flex align-items-center flex-column', styles.wrapper)}
    >
      <img src={profileImg} className={styles.img} alt="Sebastian's " />
      <div className={styles.name}>Sebastian Krzyżanowski</div>
      <AboutMeText />
    </div>
  </HomeSnippetWrapper>
);

export default memo(AboutMeSnippet);
