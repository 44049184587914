import React, { memo } from 'react';
import Home from 'components/Home';
import './styles/styles.scss';

function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default memo(App);
