import React, { memo } from 'react';
import { node, string } from 'prop-types';
import styles from 'components/common/Timeline/styles.module.scss';

const EventParagraph = ({ header, children }) => (
  <div className={styles.description}>
    <i className={styles.header}>
      <u>{header}</u>:
    </i>
    <br />
    {children}
  </div>
);

EventParagraph.propTypes = {
  header: string.isRequired,
  children: node,
};

EventParagraph.defaultProps = {
  children: null,
};

export default memo(EventParagraph);
