import React from 'react';

import AboutMeSnippet from 'components/Home/AboutMeSnippet';
import SkillsSnippet from 'components/Home/SkillsSnippet';
import ProjectsSnippet from 'components/Home/ProjectsSnippet';
import TestimonialsSnippet from 'components/Home/TestimonialsSnippet';
import ContactSnippet from 'components/Home/ContactSnippet';
import TimelineSnippet from 'components/Home/TimelineSnippet';

import styles from './styles.module.scss';

const Home = () => (
  <div className={styles.grid}>
    <AboutMeSnippet />
    <SkillsSnippet />
    <TimelineSnippet />
    <TestimonialsSnippet />
    <ProjectsSnippet />
    <ContactSnippet />
  </div>
);

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
