import React, { memo } from 'react';
import cx from 'classnames';
import HomeSnippetWrapper from 'components/Home/HomeSnippetWrapper';
import HomeHeader from 'components/Home/HomeHeader';
import BodyText from 'components/common/BodyText';

import styles from './styles.module.scss';

const renderSkills = (data, passedTreeDepth = 0) => (
  <ol className={passedTreeDepth === 1 ? styles.list : ''}>
    {data.map(({ name, children, treeDepth, learning, planningToLearn }) => (
      <li key={name} className={styles[`level${treeDepth}`]}>
        <BodyText>
          {name}
          {learning && <span className={styles.asterisk}> *</span>}
          {planningToLearn && <span className={styles.asterisk}> **</span>}
        </BodyText>
        {children && renderSkills(children, treeDepth)}
      </li>
    ))}
  </ol>
);

const SkillsSnippet = () => {
  const data = [
    {
      name: 'Frontend',
      treeDepth: 1,
      children: [
        {
          name: 'CSS',
          treeDepth: 2,
          children: [
            {
              name: 'CSS Modules',
              treeDepth: 3,
            },
            {
              name: 'SCSS',
              treeDepth: 3,
            },
            {
              name: 'styled-components',
              treeDepth: 3,
            },
            {
              name: 'Material UI',
              treeDepth: 3,
            },
            {
              name: 'Chakra UI',
              treeDepth: 3,
            },
          ],
        },
        {
          name: 'JavaScript',
          treeDepth: 2,
          children: [
            {
              name: 'React',
              treeDepth: 3,
            },
            {
              name: 'Redux',
              treeDepth: 3,
            },
            {
              name: 'Redux-Saga',
              treeDepth: 3,
            },
            {
              name: 'Next.js',
              treeDepth: 3,
            },
            {
              name: 'Apollo Client',
              treeDepth: 3,
            },
            {
              name: 'TypeScript',
              treeDepth: 3,
            },
            {
              name: 'Electron',
              treeDepth: 3,
            },
          ],
        },
        {
          name: 'Charts',
          treeDepth: 2,
          children: [
            {
              name: 'd3',
              treeDepth: 3,
            },
            {
              name: 'Victory Charts',
              treeDepth: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'Backend',
      treeDepth: 1,
      children: [
        {
          name: 'GraphQL',
          treeDepth: 2,
        },
        {
          name: 'Node.js',
          treeDepth: 2,
          children: [
            {
              name: 'Express',
              treeDepth: 3,
            },
            {
              name: 'NestJS',
              treeDepth: 3,
            },
          ],
        },
        {
          name: 'Databases',
          treeDepth: 2,
          children: [
            {
              name: 'MongoDB',
              treeDepth: 3,
            },
            {
              name: 'Firebase',
              treeDepth: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'Misc.',
      treeDepth: 1,
      children: [
        {
          name: 'Accessibility',
          treeDepth: 2,
        },
        {
          name: 'Design System implementation',
          treeDepth: 2,
        },
        {
          name: 'Publishing to NPM',
          treeDepth: 2,
        },
        {
          name: 'Tooling',
          treeDepth: 2,
          children: [
            {
              name: 'git',
              treeDepth: 3,
            },
            {
              name: 'webpack',
              treeDepth: 3,
            },
            {
              name: 'rollup.js',
              treeDepth: 3,
            },
          ],
        },
        {
          name: 'Testing',
          treeDepth: 2,
          children: [
            {
              name: 'Jest',
              treeDepth: 3,
            },
            {
              name: 'react-testing-lib',
              treeDepth: 3,
            },
            {
              name: 'cypress.io',
              treeDepth: 3,
            },
            {
              name: 'Postman',
              treeDepth: 3,
            },
          ],
        },
        {
          name: 'CI/CD',
          treeDepth: 2,
          children: [
            {
              name: 'CircleCI',
              treeDepth: 3,
            },
            {
              name: 'Travis CI',
              treeDepth: 3,
            },
            {
              name: 'Github Actions',
              treeDepth: 3,
            },
          ],
        },
        {
          name: 'Documentation',
          treeDepth: 2,
          children: [
            {
              name: 'Docz',
              treeDepth: 3,
            },
            {
              name: 'Storybook',
              treeDepth: 3,
            },
          ],
        },
        {
          name: 'CMS',
          treeDepth: 2,
          children: [
            {
              name: 'Contentful',
              treeDepth: 3,
            },
          ],
        },
      ],
    },
  ];

  return (
    <HomeSnippetWrapper>
      <HomeHeader>Skills</HomeHeader>
      <div className={styles.skillsWrapper}>
        <div className={styles.skillsTree}>{renderSkills(data)}</div>
        <BodyText className={cx('mt-3 mb-0', styles.note)}>
          * - learning / expanding my knowledge about this topic
        </BodyText>
        <BodyText className={cx(styles.note)}>
          ** - planning to explore this tech next
        </BodyText>
      </div>
    </HomeSnippetWrapper>
  );
};

export default memo(SkillsSnippet);
