import React, { memo } from 'react';
import { string, node } from 'prop-types';
import Header from 'components/common/Header';

import EventSubtitle from 'components/common/Timeline/Event/Subtitle';
import EventParagraph from 'components/common/Timeline/Event/Paragraph';

import styles from 'components/common/Timeline/styles.module.scss';

const Event = ({ interval, children, title, subtitle, jobTitle }) => (
  <li className={styles.event}>
    <label className={styles.icon} />
    <div className={styles.body}>
      <p className={styles.date}>
        {interval} {jobTitle && <span>{jobTitle}</span>}
      </p>
      {subtitle ? (
        <div className={styles.bodyHeader}>
          <Header as="h3">{title}</Header> <Header as="h4">{subtitle}</Header>
        </div>
      ) : (
        <Header as="h3">{title}</Header>
      )}
      {children}
    </div>
  </li>
);

Event.propTypes = {
  title: string.isRequired,
  subtitle: string,
  interval: string.isRequired,
  jobTitle: string.isRequired,
  children: node,
};

Event.defaultProps = {
  subtitle: null,
  children: null,
};

const MemoizedEvent = memo(Event);

MemoizedEvent.Paragraph = EventParagraph;
MemoizedEvent.Subtitle = EventSubtitle;

export default MemoizedEvent;
